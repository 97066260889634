<template>
	<v-content class="menu">

		<SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'menu_shops', query: { feature_id: 'menu'}}" ></SelectedShop>
		<v-list class="px-4">
			<v-list-item v-for="item in items" :key="item.title" @click="location(item.id)" class="px-0 mb-3">
					<v-list-item-avatar size="72">
						<v-img :src="item.thumbnail_image_path"></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title v-text="item.title"></v-list-item-title>
					</v-list-item-content>

					<v-list-item-icon class="flexcenter">
						<v-icon small class="maincolor">fas fa-chevron-right</v-icon>
					</v-list-item-icon>
			</v-list-item>

            <!-- InfiniteLoading -->
            <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
                <div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
                <div slot="no-more"></div>
                <div slot="no-results">No data</div>
            </infinite-loading>

		</v-list>

	</v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import SelectedShop from '@/components/selected_shop.vue'
export default {
    components: {
        InfiniteLoading,
        SelectedShop,
    },
	data: () => ({
        page: 1,
		items: [],
        init: [],
	}),
	methods: {
		location(value) {
			this.$router.push({name:'menu', query: { category_id: [value] }})
		},
        async infiniteHandler() {
            this.init = this.storageGet('*')
            var shop_id = this.getUrlGetParam('shop_id');
            var selected_shop = this.getUrlGetParam('selected_shop');
            var params = {
                'page': this.page
            }
            if (selected_shop !== undefined) {
                this.selected_shop = !selected_shop ? '全て' : selected_shop;
                params.shop_id = !shop_id ? this.init.sid : shop_id;
            } else {
                params.shop_id = this.init.sid;
            }

            var reqest_params = { params: params}

            var res = await this.apiCall('/menu/category', reqest_params)

            if(res.data){
              if (this.page == 1) {
                  this.$set(this, 'result', res)
                  this.$set(this, 'items', res.data)
              } else {
                  this.items = this.items.concat(res.data);
              }
            }

            this.page = this.page + 1

            if (res.data && res.data.length > 0) {
                this.$refs.infiniteLoading.stateChanger.loaded()
            } else {
                this.$refs.infiniteLoading.stateChanger.complete()
            }
        },
        created () {
          this.init = this.storageGet('*')

          var selected_shop = this.getUrlGetParam('selected_shop');

          if (selected_shop) {
              this.selected_shop = selected_shop
          } else {
              this.selected_shop = '全て'
          }
        },
	}
}
</script>

<style scoped>
.flexcenter {
	align-self: center !important;
}
</style>
